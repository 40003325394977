import React from "react";
import Base from "../core/Base";

const Hod = () => {
  return (
    <div>
      <Base>HODs</Base>
    </div>
  );
};

export default Hod;
