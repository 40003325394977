import React from "react";
import Base from "../core/Base";

const FormerFaculty = () => {
  return (
    <div>
      <Base>FormerFaculty</Base>
    </div>
  );
};

export default FormerFaculty;
