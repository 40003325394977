import React from "react";
import Base from "../core/Base";

const DepattCommities = () => {
  return (
    <div>
      <Base>DepattCommities</Base>
    </div>
  );
};

export default DepattCommities;
