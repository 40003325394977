import React from "react";
import DropDownMenu from "./dropdownmenu";
import { Link } from "react-router-dom";

const people = [
  { href: "/members", label: "All Members" },
  { href: "/director", label: "Director" },
  { href: "/foundingMembers", label: "Founding Members" },
  { href: "/execDirector", label: "Executive Director" },
  { href: "/boardofDirectors", label: "Board Of Directors" },
  { href: "/executiveBoardMembers", label: "Executive Board Members" },
  { href: "/activeParticipants", label: "Active Participants" },
  { href: "/followers", label: "Followers" },
];

const aboutus = [
  { href: "/ourVision", label: "Our Vision" },
  { href: "/dirMsg", label: "Director's Message" },
];

const publications = [
  { href: "/recentPub", label: "Recent Publications" },
  { href: "/asdsPub", label: "ASDS Publications" },
  { href: "/researchAreas", label: "Research Areas" },
];

const home = [
  { href: "/fellowshipProg", label: "Fellowship Programs" },
  { href: "/noticeBoard", label: "Notice Board" },
  { href: "/registerConf", label: "Register For Conference" },
  { href: "/awards", label: "Awards" },
];

const events = [
  { href: "/calendars", label: "Calendars" },
  { href: "/conferences", label: "Conferences" },
  { href: "/webinarHistory", label: "Webinar History" },
  { href: "/upcomingWebinars", label: "Upcoming Webinars" },
];

const Header = () => {
  return (
    <>
      <header className="text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <Link
            to="/"
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              className="w-10 h-10 text-white p-2 bg-indigo-900 rounded-full"
              viewBox="10 20 2 2"
            >
              <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
            </svg>
            <span className="ml-3 text-xl">DYNAMIC STUDIES</span>
          </Link>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
            <Link to="/" className="mr-5 hover:text-gray-900">
              <DropDownMenu links={home} option="Home" />
            </Link>
            <Link to="/members" className="mr-5 hover:text-gray-900">
              <DropDownMenu links={people} option="People" />
            </Link>
            <Link to="/about" className="mr-5 hover:text-gray-900">
              <DropDownMenu links={aboutus} option="About Us" />
            </Link>
            <Link to="/events" className="mr-5 hover:text-gray-900">
              <DropDownMenu links={events} option="Events" />
            </Link>
            <Link to="/publications" className="mr-5 hover:text-gray-900">
              <DropDownMenu links={publications} option="Publications" />
            </Link>
            <Link to="/gallery" className="mr-5 hover:text-gray-900">
              Gallery
            </Link>
            <Link to="/contact" className="mr-5 hover:text-gray-900">
              Contact
            </Link>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
