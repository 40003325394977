import React from "react";
import Base from "../core/Base";

const SupportingStaffs = () => {
  return (
    <div>
      <Base>Supporting staffs</Base>
    </div>
  );
};

export default SupportingStaffs;
