import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import "./index.css";
import About from "./core/About";
import Contact from "./core/Contact";
import Publications from "./core/Publications";
import Members from "./People/Members";
import Hod from "./People/Hod";
import SupportingStaffs from "./People/SupportingStaffs";
import Tutors from "./People/Tutors";
import UsefulLinks from "./People/UsefulLinks";
import Home from "./core/Home";
import FormerFaculty from "./People/FormerFaculty";
import Achievements from "./People/Achievements";
import DepattCommities from "./People/DepattCommities";
import Gallery from "./core/Gallery";
import MemberDetails from "./People/MemberDetails";
import Error from "./core/Error";

const router = createBrowserRouter([
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/publications",
    element: <Publications />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/members",
    element: <Members />,
  },
  {
    path: "/member/:memberId",
    element: <MemberDetails />,
  },
  {
    path: "/supportingStaffs",
    element: <SupportingStaffs />,
  },
  {
    path: "/tutors",
    element: <Tutors />,
  },
  {
    path: "/depattCommities",
    element: <DepattCommities />,
  },
  {
    path: "/hods",
    element: <Hod />,
  },
  {
    path: "/facultyAchivements",
    element: <Achievements />,
  },
  {
    path: "/usefulLinks",
    element: <UsefulLinks />,
  },
  {
    path: "/formerFaculties",
    element: <FormerFaculty />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "*",
    element: <Error />,
    errorElement: <Error />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// https://reactrouter.com/en/main/components/nav-link
