import React from "react";
import Base from "../core/Base";

const Tutors = () => {
  return (
    <div>
      <Base>Tutors</Base>
    </div>
  );
};

export default Tutors;
