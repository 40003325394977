import React from "react";
import Base from "../core/Base";

const UsefulLinks = () => {
  return (
    <div>
      <Base>Useful links</Base>
    </div>
  );
};

export default UsefulLinks;
