// https://www.linxtion.com/demo/react-image-gallery/

import React from "react";
import Base from "./Base";
import ImageGallery from "react-image-gallery";
var listOfImages = [];

const Gallery = () => {
  function importAll(r) {
    return r.keys().map(r);
  }

  listOfImages = importAll(
    require.context("../../Gallery/", false, /\.(png|jpe?g|svg)$/)
  );

  const images = listOfImages.map((image) => {
    return {
      original: image,
      thumbnail: image,
      //   originalHeight: "30rem",
      //   originalWidth: "10%",
      //   thumbnailHeight: 50,
      //   thumbnailWidth: 50,
      loading: "lazy",
    };
  });

  console.log(images);

  //   return (
  //     <div>
  //       <Base>
  //         <section className="overflow-hidden text-gray-700">
  //           <div className="container px-5 py-2 mx-auto lg:pt-24 lg:px-32">
  //             <div className="flex flex-wrap -m-1 md:-m-2">
  //               {listOfImages.map((image, index) => (
  //                 <div className="flex flex-wrap w-1/3">
  //                   <div className="p-1 md:p-2">
  //                     <img
  //                       alt="gallery"
  //                       className="block object-contain object-center w-full h-full rounded-lg"
  //                       src={image}
  //                       key={index}
  //                     />
  //                   </div>
  //                   {/* <div className="w-1/2 p-1 md:p-2">
  //                     <img
  //                       alt="gallery"
  //                       className="block object-cover object-center w-full h-full rounded-lg"
  //                       src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp"
  //                     />
  //                   </div>
  //                   <div className="w-full p-1 md:p-2">
  //                     <img
  //                       alt="gallery"
  //                       className="block object-cover object-center w-full h-full rounded-lg"
  //                       src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
  //                     />
  //                   </div> */}
  //                 </div>
  //               ))}
  //               {/* <div className="flex flex-wrap w-1/2">
  //                 <div className="w-full p-1 md:p-2">
  //                   <img
  //                     alt="gallery"
  //                     className="block object-cover object-center w-full h-full rounded-lg"
  //                     src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp"
  //                   />
  //                 </div>
  //                 <div className="w-1/2 p-1 md:p-2">
  //                   <img
  //                     alt="gallery"
  //                     className="block object-cover object-center w-full h-full rounded-lg"
  //                     src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp"
  //                   />
  //                 </div>
  //                 <div className="w-1/2 p-1 md:p-2">
  //                   <img
  //                     alt="gallery"
  //                     className="block object-cover object-center w-full h-full rounded-lg"
  //                     src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(77).webp"
  //                   />
  //                 </div>
  //               </div> */}
  //             </div>
  //           </div>
  //         </section>
  //       </Base>
  //     </div>
  //   );

  //   return <ImageGallery items={listOfImages} />;
  return (
    <Base>
      <ImageGallery items={images} thumbnailPosition="left" showIndex="true" />
    </Base>
  );
};

export default Gallery;
