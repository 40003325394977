import React from "react";
import Base from "../core/Base";

const Achievements = () => {
  return (
    <div>
      <Base>Achievements</Base>
    </div>
  );
};

export default Achievements;
