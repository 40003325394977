import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Base = ({
  //   title = "My-Title",
  //   description = "My description",
  //   className = "bg-main text-white ps-4",
  children,
}) => {
  return (
    <div>
      <Header />
      <div className="container mx-auto">{children}</div>
      <Footer />
    </div>
  );
};

export default Base;
