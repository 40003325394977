import React from "react";
import Base from "./Base";

const Error = () => {
  return (
    <div>
      <Base>
        <h1 className="header text-6xl text-center font-bold my-60">
          This page is under construction
        </h1>
      </Base>
    </div>
  );
};

export default Error;
