import React, { useEffect, useState } from "react";
import Base from "../core/Base";
import sanityClient from "../client";
import { Link } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import userImg from "../assets/images/user.png";
// const axios = require("axios").default;
// import axios from "axios";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const Members = () => {
  const [members, setMembers] = useState([]);
  // const url = "https://dynamicstudies.org/assets/json/members.json";

  // const loadAllMembers = () => {
  //   axios
  //     .get(url)
  //     .then((members) => {
  //       setMembers(members.data);
  //       console.log(members.data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const loadMembers = () => {
    sanityClient
      .fetch(`*[_type == "user"]`)
      .then((data) => {
        setMembers(data);
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadMembers();
    // loadAllMembers();
  }, []);

  return (
    <div>
      <Base>
        <section className="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap -m-4">
              {members.map((member, index) => (
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full" key={index}>
                  <Link className="block relative h-48 rounded overflow-hidden">
                    <img
                      className="object-contain h-full block rounded-full"
                      src={
                        member.displayimage
                          ? urlFor(member.displayimage).url()
                          : userImg
                      }
                      alt="faculty"
                    />
                  </Link>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      {member?.designation[0]}
                    </h3>
                    <h2 className="text-gray-900 title-font text-lg font-medium">
                      {member?.name}
                    </h2>
                    <p className="mt-1">{member?.depatt}</p>
                    <Link
                      to={`/member/${member.slug.current}`}
                      className="text-blue-700 font-bold"
                    >
                      Click Here
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Base>
    </div>
  );
};

export default Members;
