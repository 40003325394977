import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Base from "../core/Base";
// import member_details from "../assets/json/members.json";
import { useParams } from "react-router-dom";
import axios from "axios";

const FacultyDetails = ({ resume }) => {
  let { memberId } = useParams();

  const [members, setMembers] = useState([]);
  const url = "https://dynamicstudies.org/assets/json/members.json";

  const loadAllMembers = () => {
    axios
      .get(url)
      .then((members) => {
        setMembers(members.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadAllMembers();
    console.log(members);
  }, []);

  try {
    const docs = [
      {
        uri: require(`../assets/documents/${members[memberId - 1]?.resume}`),
      },
    ];
    return (
      <div>
        <Base>
          <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            className="h-1/3"
          />
        </Base>
      </div>
    );
  } catch (error) {
    return (
      <Base>
        <div className="text-center">
          <h1 className="text-xl text-purple-800 font-bold md:text-3xl lg:text-6xl">
            Member Details not available
          </h1>
        </div>
      </Base>
    );
  }
};

export default FacultyDetails;
