import React from "react";
import MovingText from "react-moving-text";
import { Link } from "react-router-dom";
import sanityClient from "../client";
import Base from "./Base";
import img from "../assets/images/homecontent.webp";
import { useState } from "react";
import { useEffect } from "react";

const Home = () => {
  const [notice, setNotice] = useState({});

  const loadNotice = () => {
    sanityClient
      .fetch(`*[_type == "notice"] | order(_createdAt desc)`)
      .then((notice) => {
        setNotice(notice);
        console.log(notice);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    loadNotice();
  }, []);
  return (
    <Base>
      <div className="container mx-auto">
        {/* Moving text container*/}
        <div className="w-full mt-20 text-3xl">
          <MovingText
            type="slideInFromRight"
            duration="20000ms"
            delay="0s"
            direction="alternate-reverse"
            timing="ease"
            iteration="infinite"
            fillMode="forwards"
          >
            <span>
              <span>
                <Link to="/" className="underline mx-10">
                  {/* Notice description */}
                  {notice ? notice[0]?.description : ""}
                </Link>
              </span>
            </span>
          </MovingText>
        </div>
        <section className="text-gray-600 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={img}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className=" text-purple-900 sm:text-2xl text-base mb-4 font-medium">
                School of Dynamics Studies is an orgnization for building an
                community of researchers and academicians platform for advanced
                studies in dynamical system and concerned areas. We invite
                interested people to join our orgnization.
                <br className="hidden lg:inline-block" />
              </h1>
              <p className="title-font mb-8 leading-relaxed text-3xl text-black">
                We welcome you all to join.
              </p>
            </div>
          </div>
        </section>

        {/* text center quote */}
        {/* <div className="text-center">
          <p className="text-2xl font-bold">
            "Mathematics reveals its secrets only to those who approach it with
            pure love, for its own beauty"- Archimedes.{" "}
          </p>
          <p className="text-xl mt-12">
            Welcome to the website of the Department of Mathematics, University
            of Delhi. Our faculty members specialize in various areas of
            algebra, analysis and applied mathematics. We offer M.A./M.Sc.
            program (in both north and south campus) as well as M.Phil. and Ph.D
            programs in Mathematics.
          </p>
        </div> */}
      </div>
    </Base>
  );
};

export default Home;
